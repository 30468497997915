import React from 'react';

const StartupLanding = () => {
  const examples = [
    {
      title: 'Interview',
      description: 'We conduct a two-hour interview with a person who wants to leave a legacy, optionally adding information from their social media provided.'
    },
    {
      title: 'Processing',
      description: 'We process all the data about the person to make it easy to access for family.'
    },
    {
      title: 'Usage',
      description: 'Your loved ones will be able to get wisdom, or listen to the interesting stories of your life even when you are not around.'
    }
  ];

  const handleJoinWaitlist = () => {
    window.open('https://forms.gle/bYyW2aXU7YLbEjbFA', '_blank');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0F172A] via-[#1E1B4B] to-[#1E1B4B] text-white">
      {/* Hero Section */}
      <div className="relative h-[90vh] flex items-center justify-center">
        <div className="absolute inset-0 opacity-30">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-900/30 to-blue-900/30 backdrop-blur-3xl" />
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzEyZTgxIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=')] opacity-20" />
        </div>
        
        <div className="relative px-6 text-center max-w-7xl">
          <h1 className="text-6xl md:text-8xl font-bold tracking-tight bg-gradient-to-r from-white via-purple-100 to-blue-100 bg-clip-text text-transparent mb-6">
            Stay With Me
          </h1>
          <p className="text-xl md:text-2xl text-indigo-100 mb-10">
            Your digital legacy
          </p>
          <button 
            onClick={handleJoinWaitlist}
            className="rounded-full bg-indigo-600 px-8 py-4 text-sm font-semibold text-white 
                     shadow-lg hover:bg-indigo-500 transition-all duration-200 flex items-center gap-2 
                     hover:-translate-y-0.5 border border-indigo-400/20 mx-auto"
          >
            Join Waitlist →
          </button>
        </div>

        {/* Scroll Indicator */}
        <div className="absolute bottom-8 left-0 right-0 flex flex-col items-center animate-bounce">
          <p className="text-sm text-indigo-200 mb-2">Scroll to learn more</p>
          <span className="text-2xl text-indigo-200">↓</span>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="py-16 px-6">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl bg-gradient-to-r from-white via-purple-100 to-blue-100 bg-clip-text text-transparent">
              How It Works
            </h2>
          </div>
          
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:max-w-none lg:grid-cols-3">
            {examples.map((example, index) => (
              <div
                key={index}
                className="relative rounded-2xl bg-white/5 p-8 hover:-translate-y-1 
                         transition-all duration-200 border border-white/10 backdrop-blur-sm
                         hover:bg-white/10"
              >
                <h3 className="text-xl font-semibold leading-7 bg-gradient-to-r from-white via-purple-100 to-blue-100 bg-clip-text text-transparent">
                  {example.title}
                </h3>
                <p className="mt-4 text-indigo-100 leading-7">
                  {example.description} 
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupLanding;