import logo from './logo.svg';
import './App.css';
import StartupLanding from './page/StartupLanding'

function App() {
  return (
    <div className="App">
      <StartupLanding />
    </div>
  );
}

export default App;
